import Alpine from "alpinejs";
import { intersect } from "@alpinejs/intersect";

if (process.env.NODE_ENV !== 'production') {
    console.log('zukunft-musik');
}

Alpine.plugin(intersect);

window.Alpine = Alpine;


Alpine.data('accordion', () => ({
    open: false,
    toggle(x) {
        this.open = this.isActive(x) ? false : x;
    },

    isActive(x) {
        return this.open === x;
    },

    getStyle(x) {
        const height = this.isActive(x) ? this.$refs[x]?.scrollHeight + 20 || 0 : 0;
        return `max-height: ${height}px`
    },
    isHidden(x) {
        return !this.isActive(x) && !this.isActive(false)
    }
}))

Alpine.data('section', () => ({
    initial: true,
    section: 1,
    touchstart: undefined,
    toggleInitial(e) {
        const scrollDown = e.deltaY > 0;
        this.initial = this.initial && scrollDown ? false : !this.initial && !scrollDown;
    },
    onTouchStart(e) {
        this.touchstart = e.touches[0].clientY;
    },
    onTouchEnd(e) {
        const touchend = e.changedTouches[0].clientY;
        const swipeDown = this.touchstart > touchend + 5;
        this.initial = this.initial && swipeDown ? false : !this.initial && !swipeDown;
    },
    section2Full: false,
    scrollToSection(id) {
        const element = document.querySelector(`#${id}`)
        const topPos = element.getBoundingClientRect().top + window.scrollY;
        console.log(topPos);
        document.body.scrollTo({
            top: topPos, // scroll so that the element is at the top of the view
            behavior: 'smooth' // smooth scroll
        })
    }
}))

Alpine.start();